<template>
  <div>
    <el-row
      v-for="(item, index) of form"
      :key="index"
      :gutter="20"
      class="mb-4 last:mb-0"
    >
      <el-col :sm="10" :md="10" :lg="6" :xl="5" class="xs:mb-2">
        <!-- Amount -->
        <th-input-title
          v-if="index === 0"
          :title="$t('common.forms.labels.amount')"
          title-for="amount"
        />
        <th-currency-input
          id="amount"
          class="w-full"
          :model-value="item.amount"
          :currency="item.currency"
          @update:modelValue="(v) => setValue(index, 'amount', v)"
        />
      </el-col>

      <el-col v-if="currencies.length > 1" :sm="14" :md="14" :xl="16">
        <!-- Currency -->
        <th-input-title
          v-if="index === 0"
          :title="$t('common.forms.labels.currency')"
        />
        <th-currency-select
          v-model="item.currency"
          data-testid="currency"
          class="inline-block w-32"
          :filtered-currencies="usedCurrencies"
          :allowed-currencies="currencies"
          @update:modelValue="(v) => setValue(index, 'currency', v)"
        />

        <!-- Delete -->
        <el-button
          icon="Delete"
          class="ml-2 el-button--text-icon"
          plain
          @click="deleteItem(index)"
        />

        <!-- Add -->
        <el-button
          v-if="form.length === index + 1 && currencies.length > 1"
          :disabled="!noCurrencies"
          :aria-label="$t('common.actions.addmore')"
          class="el-button--primary-icon ml-2 button-mini"
          plain
          icon="Plus"
          @click="addNewItem"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { computed } from 'vue'
import { mapGetters, useStore } from 'vuex'
import get from 'just-safe-get'
import currenciesMixin from '@/views/products/v2/mixins/currencies'
export default {
  props: {
    field: {
      type: String,
      required: true
    },
    categoryName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const form = computed(() =>
      get(
        store.getters['Config/getClientAccountConfiguration'],
        `${props.categoryName}.${props.field}`
      )
    )
    const {
      noCurrencies,
      availableCurrencies,
      usedCurrencies
    } = currenciesMixin(form)
    return {
      noCurrencies,
      availableCurrencies,
      usedCurrencies,
      form
    }
  },

  computed: {
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      currencies: 'Config/getAvailableCurrencies'
    }),
    category() {
      return get(
        this.$store.getters['Config/getClientAccountConfiguration'],
        this.categoryName
      )
    }
  },

  watch: {
    /* This watcher is important, because it triggers the function initialize() in 3 scenarios:
    1. When the component is created
    2. On reloading of the page a race condition exists between re-fetching the configurations and created(). The re-fetched data will often override the data after initialize().
    3. After the general "reset" from the settings toolbar is triggered
    */
    form: {
      handler: 'initialize',
      deep: true,
      immediate: true
    }
  },

  methods: {
    getNewRow({ initial = true } = {}) {
      return {
        amount: null,
        currency: initial ? this.defaultCurrency : this.availableCurrencies[0]
      }
    },
    // Always initialize the category config property with at least one element
    // so that the user always sees at least one set of initial fields
    initialize() {
      if (!this.category) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: this.categoryName,
          value: { [this.field]: [this.getNewRow()] }
        })
      } else if (!this.category[this.field]) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: this.categoryName,
          value: {
            ...this.$deepClone(this.category),
            [this.field]: [this.getNewRow()]
          }
        })
        // Since some values are being set on the config object on component mount (as seen above),
        // the config is being set to dirty from the beginning and will prevent navigating away from the page
        // although the user didn't made any actual action to the form. Therefore, we're toggling the dirty state to false.
      }
    },
    // update this.field in the store
    updateStore(value) {
      this.$store.dispatch('Config/setClientAccountConfigurationValue', {
        path: `${this.categoryName}.${this.field}`,
        value
      })
    },
    // update "amount" and "currency" in the correct object and send it to the store
    setValue(index, path, value) {
      const updated = this.$deepClone(this.form)
      updated[index][path] = value

      this.$store.dispatch('Config/setClientAccountConfigurationValue', {
        path: `${this.categoryName}.${this.field}`,
        value: updated
      })
    },
    // add a new object to the this.field array
    addNewItem() {
      let data = this.$deepClone(this.form)

      if (Array.isArray(data)) {
        data.push(this.getNewRow({ initial: false }))
      } else {
        data = [this.getNewRow()]
      }

      this.updateStore(data)
    },
    // delete an item from the array, but keep at least one element for display
    deleteItem(index) {
      let data = this.$deepClone(this.form)
      if (this.form.length === 1) {
        data = [this.getNewRow()]
      } else {
        data.splice(index, 1)
      }
      this.updateStore(data)
    }
  }
}
</script>

<style scoped></style>
